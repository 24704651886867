import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ContactPage = () => (
  <Layout lang="fr">
    <SEO title="Get in tounch" description="Contact details for the Shift Power for Peace project" />

    <div className="container mx-auto mt-4 md:mt-16">
      <div className="md:flex p-4 md:p-0">
        <div className="md:w-1/3 mb-4 md:mb-0">
          <h1 className="text-3xl font-bold text-brand-purple">Get in touch</h1>
        </div>
        <div className="md:border-l-2 md:border-brand-purple md:pl-8 md:w-2/3 md:pb-16">
        <p className="text-xl text-brand-purple">Email: <a className="text-brand-orange" href="mailto:hello@shiftpowerforpeace.org">hello@shiftpowerforpeace.org</a></p>
          
        </div>
      </div>      
    </div>
  </Layout>
)

export default ContactPage
